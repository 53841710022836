.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 25vw);
  grid-gap: 5vw;
  place-content: center;
}

.grid-item-container {
  /* position: relative; */
}

.grid-item-footer {
  position: relative;
}

.grid-item {
  width: 25vw;
  height: auto;
  background-color: --var(--tint-color);
  object-fit: cover;
  cursor: pointer;
}

.grid-item-hover {
  display: flex;
  position: absolute;
  flex-direction: row;
  opacity: 0;
  width: 25vw;
  transition: all 0.25s cubic-bezier(1, -0.25, 0, 1.25);
  background: none;
  color: var(--tint-color);
}

.grid-item-container:hover .grid-item-hover {
  opacity: 1;
  transform: translate3d(0, -100%, 0);
}

.grid-item-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: var(--tint-color);
}

.grid-item-text-container {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap:5px;
}

.grid-item-text {
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  max-height:1.35em;
  line-clamp: 1; 
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}
.grid-item-text:hover {
  color: var(--tint-dark-color);
}
.grid-item-buttons-container{
  display: flex;
  gap:2px;
  flex-direction: column;
}

.grid-item-buttons {
  display: flex;
  color: var(--tint-color);
}

.grid-item-button {
  padding: 1px;
}
.grid-item-button:hover {
  color: var(--tint-dark-color);
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 25vw);
    grid-gap: 5vw;
    place-content: center;
  }
  
  .grid-item {
    width: 25vw;
    height: auto;
    object-fit: cover;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 25vw);
    grid-gap: 5vw;
    place-content: center;
  }

  .grid-item {
    width: 25vw;
    height: auto;
    object-fit: cover;
  }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 40vw);
    grid-gap: 5vw;
    place-content: center;
  }
  .grid-item {
    width: 40vw;
    height: auto;
    object-fit: cover;
  }
}

.grid-container.small-grid {
  display: grid;
  grid-template-columns: repeat(2, 90px);
  grid-gap: 10px;
  place-content: center;
}

.grid-item.small-grid {
  width: 90px;
  height: auto;
  object-fit: cover;
}



/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
