a:link,
a:visited,
a:active {
  color: #000;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  cursor: pointer;
  color: var(--tint-dark-color);
}

.logo {
  width: 50px;
  height: 50px;
}

.Header-logo {
  width: 50px;
  height: 50px;
  margin-left: 40px;
  cursor: pointer;
}
.Header-logo:hover {
  fill: var(--tint-dark-color);
}

.Header-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  position: relative;
  z-index: 2;
  background: white;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.Header-title {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
}

.Header-navbutton {
  padding: 14px;
  margin-left: 10px;
}

.Header-Cart-button {
  display: flex;
  place-self: end;
  align-items: center;
  color: white;
  border: none;
  gap: 10px;
  padding: 5px 10px;
  background: var(--tint-dark-color);
  cursor: pointer;
}

.Header-Cart-button svg {
  width: 10px;
}

.Header-Fav-button {
  display: flex;
  place-self: end;
  align-items: center;
  color: white;
  gap: 10px;
  border: none;
  padding: 5px 10px;
  background: var(--tint-dark-color);
  cursor: pointer;
}

.Header-Fav-button svg {
  width: 10px;
}

.Header-header a {
  cursor: pointer;
}

.Header-buttons {
  padding: 14px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-flow: row;
  margin-right: 20px;
  border-radius: 5px;
}

.Header-buttons div:not(:first-child) {
  margin-left: 20px;
}

.Header-buttons div:active {
  color: var(--tint-dark-color);
}

.Header-buttons div:hover {
  color: var(--tint-dark-color);
}

.Header-nav-dropdown {
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-flow: column;
  text-align: left;
  z-index: 1;
  background: white;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  top: 47px;
  min-width: 150px;
  padding: 20px;
  gap: 5px;
}

.Header-nav-dropdown-right {
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-flow: column;
  text-align: left;
  z-index: 1;
  background: white;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  top: 47px;
  min-width: 335px;
  right: 0px;
  padding: 20px;
  gap: 10px;
  font-weight: 500;
  max-height: 70vh;
}

.nav-link-item {
  padding: 10px;
  margin-left: 10px;
}

.Header-nav-dropdown.invisible {
  display: none;
}

.Header-nav-item {
  padding-left: 10px;
  display: flex;
  cursor: pointer;
}

.margin-left {
  margin-left: 10px;
}

.Header-nav-item:hover {
  color: var(--tint-dark-color);
}

.Header-nav-categories-hidden {
  display: none;
}

.Header-nav-categories {
  display: flex;
  flex-flow: column;
  padding-left: 14px;
  margin-top: 7px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .Header-logo {
    margin-left: 30px;
    cursor: pointer;
  }

  .Header-nav-dropdown {
    min-width: 150px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 400px) {
  .Header-nav-dropdown {
    min-width: 150px;
  }

  .Header-logo {
    margin-left: 20px;
    cursor: pointer;
  }
}

.small-flyout {
  min-width: 150px;
}

.medium-flyout {
  min-width: 200px;
}