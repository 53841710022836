
.Cart-container {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 25px;
  overflow-y: auto;
}

.Cart-title {
  margin: 0px;
}

.Cart-summary-box {
  background: var(--background-color);
  display: flex;
  flex-flow: column;
  position: fixed;
  top: 20vh;
  right: -2px;
  height: 250px;
  width: 202px;
  padding: 32px;
  z-index: 1;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  gap: 10px;
}

.Cart-checkout {
  cursor: pointer;
  text-align: center;
  background: var(--tint-dark-color);
  margin-top: 10vh;
  color: white;
}

.Cart-Items {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.Cart-shipping-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.Cart-shipping-submit {
  cursor: pointer;
  background: var(--tint-dark-color);
  padding: 5px 10px;
  color: white;
  align-self: center;
}

.CartItem-container {
  display: flex;
  flex-flow: row;
}

.CartItem-content {
  display: flex;
  flex-flow: column;
  font-size: medium;
  margin-left: 32px;
  gap: 16px;
}

.CartItem-remove {
  cursor: pointer;
  color: red;
}

.Cart-empty-container {
  display: flex;
  flex-flow: column;
  place-items: center;
  justify-content:center;
}

.Cart-store-button {
  color: var(--tint-color);
  cursor: pointer;
}

.Cart-store-button :hover {
  color: var(--tint-dark-color);
}

.CartItem-content {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.CartItem-image {
  width: 150px;
  height: auto;
}

.Cart-checkout {

}

.Cart-shipping-input {
  padding: 5px 10px;
  align-self: center;
}

.Cart-shipping-submit {
  background: var(--tint-dark-color);
  padding: 5px 10px;
  color: white;
  align-self: center;
}

.Cart-subtotal-container {
  display: flex;
  flex-flow:row;
  justify-content: space-between;
}

.Cart-button {
  padding:10px;
  background: var(--tint-dark-color);
  color: white;
  cursor: pointer;
  border-color: transparent;
}

.CartItem-bottom-buttons{
  display:flex;
  flex-flow:row;
  gap: 20px;
  justify-content: space-between;
  color: var(--tint-color);
}

.CartItem-buttons-left {
  display: flex;
  flex-flow:row;
  gap: 20px;
  cursor:pointer;
}

.CartItem-buttons-right {
  font-size: large;
  cursor:pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Cart-container {
    display: flex;
    flex-flow: column;
    gap:32px;
  }

  .CartItem-photo {
    width: 30vw;
    height: auto;
  }

  .CartItem-content {
    display: flex;
    flex-flow: column;
    font-size: 16px;
    gap: 10px;
  }

  .Cart-summary-box  {
    position: initial;
    height: auto;
    width: 70vw;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    background: white;
  }
  .Cart-checkout {
    width: 60vw;
  }
  .Cart-shipping-input {
    padding: 5px 10px;
    width:25vw;
    align-self: center;
  }
  .Cart-shipping-submit {
    background: var(--tint-dark-color);
    padding: 5px 10px;
    color: white;
    align-self: center;
  }
}