.Fav-container {
  display: flex;
  flex-flow: column;
  gap: 32px;
  overflow-y: auto;
}

.Fav-title { 
  margin:0;
}

.Fav-summary-box {
  display: flex;
  flex-flow: column;
  height: 400px;
  width: 200px;
  padding: 32px;
  z-index: 2;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  gap: 10px;
}

.Fav-checkout {
  cursor: pointer;
  text-align: center;
  background: var(--tint-dark-color);
  margin-top: 10vh;
  color: white;
}

.Fav-Items {
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.Fav-shipping-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.Fav-shipping-submit {
  cursor: pointer;
  background: var(--tint-dark-color);
  padding: 5px 10px;
  color: white;
  align-self: center;
}

.FavItem-container {
  display: flex;
  flex-flow: row;
}

.FavItem-content {
  display: flex;
  flex-flow: column;
  padding: 0px 32px;
  font-size: medium;
  gap: 16px;
}

.FavItem-remove {
  cursor: pointer;
  color: red;
}

.Fav-empty-container {
  display: flex;
  flex-flow: column;
  place-items: center;
  justify-content: center;
}

.Fav-store-button {
  color: var(--tint-color);
  cursor: pointer;
}

.Fav-store-button :hover {
  color: var(--tint-dark-color);
}

.Fav-summary-box {
  position: initial;
  height: auto;
}

.FavItem-content {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

.FavItem-image {
  width: 150px;
  height: auto;
}

.Fav-checkout {}

.Fav-shipping-input {
  padding: 5px 10px;
  align-self: center;
}

.Fav-shipping-submit {
  background: var(--tint-dark-color);
  padding: 5px 10px;
  color: white;
  align-self: center;
}