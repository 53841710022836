a:link, a:visited, a:active {
  color: var(--secondary-color);
  background-color:transparent;
  text-decoration: none;
}

a:hover {
  color: var(--info-color);
}

footer {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;
}